import { useEffect, useState } from 'react';
import { gapi } from 'gapi-script';

const CLIENT_ID = '332898887822-i288mff9d1s02vmecibot29gle7c30fv.apps.googleusercontent.com';
const API_KEY = process.env.REACT_APP_GOOGLE_PHOTOS_API_KEY;
const DISCOVERY_DOCS = ['https://photoslibrary.googleapis.com/$discovery/rest?version=v1'];
const SCOPES = 'https://www.googleapis.com/auth/photoslibrary.readonly';
// const CLIENT_SECRET = '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';
// const PROJECT_ID = 'avid-sunset-385814';
const PROJECT_NAME = 'lsuwcsoccer';

export default function MediaItems() {
    const [albums, setAlbums] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://apis.google.com/js/api.js';
        script.onload = () => {
            gapi.load('auth2', () => {
                gapi.auth2.init({
                    apiKey: API_KEY,
                    clientId: CLIENT_ID,
                    discoveryDocs: DISCOVERY_DOCS,
                    scope: SCOPES,
                    plugin_name: PROJECT_NAME,
                }).then(() => {
                    gapi.auth2.getAuthInstance().signIn().then(() => {
                        // User is now authenticated
                        // Retrieve access token
                        const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
                        gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse({ access_token: accessToken }).then(() => {
                            gapi.auth2.getAuthInstance().currentUser.get().grant({
                                scope: "https://www.googleapis.com/auth/photoslibrary.readonly"
                            }).then(() => {
                                // this is executed
                                gapi.auth2.getAuthInstance().currentUser.get().getGrantedScopes();
                                gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse();
                                gapi.auth2.getAuthInstance().currentUser.get().getId();
                                gapi.auth2.getAuthInstance().currentUser.get().isSignedIn();
                                gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse();
                                gapi.auth2.getAuthInstance().currentUser.get().disconnect();
                                gapi.auth2.getAuthInstance().currentUser.listen(() => {
                                    // User state changed
                                });
                                gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();
                                gapi.client.request({
                                    path: 'https://photoslibrary.googleapis.com/v1/albums',
                                    method: 'GET',
                                    params: {
                                        pageSize: 10,
                                        excludeNonAppCreatedData: true,
                                    },
                                }).then((response: any) => {
                                    console.log("Response: ", response);
                                    if(!response || !response.result || !response.result.albums){
                                        console.error("Error retrieving albums: Response is not valid: ", response);
                                    }
                                    else {
                                        const albums = response.result.albums;
                                        console.log("response: ", response);
                                        console.log("response.result: ", response.result);
                                        console.log("response.result.albums: ", response.result.albums);
                                        console.log("albums before: ", albums);
                                        setAlbums(albums);
                                        setIsLoading(false);
                                        console.log("Albums after: ", albums);
                                    }
                                }).catch((error: Error) => {
                                    console.error("Error retrieving albums: ", error.message);
                                    setIsLoading(false);
                                });
                            })
                        });
                    }).catch((error: Error) => {
                        console.error("Error authenticating user: ", error);
                    });
                }).catch((error: Error) => {
                    console.error("Error initializing client: ", error.message);
                });
            });
        };
        document.body.appendChild(script);
    }, []);

    return (
        <div>
            {isLoading ? (
                <p>Loading albums...</p>
            ) : (
                albums.map((album: any) => (
                    <div key={album.id}>
                        <p>{album.title}</p>
                        <img src={album.coverPhotoBaseUrl} alt={album.title} />
                    </div>
                    ))
            )}
        </div>
    );
}