import React from 'react';
import Image from './ImageProps';

const imagesInfo = [
    {name: "selfie", src: "./team_selfie.JPG"},
    {name: "huddle", src: "./huddle.JPG"},
    {name: "funPic", src: "./team_funny_pic.JPG"}
];

const displayImages = () => {
    const image = imagesInfo.map((image, index) =>
        <Image src = { imagesInfo[index].src } alt = { imagesInfo[index].name } key={index}/>
    );
    return image;
}

const ImagesContainer: React.FC = () => (
    <div className="flex">
        { displayImages() }
    </div>
);

export default ImagesContainer;