interface OfficerPreviewProps {
    firstName: string,
    lastName: string,
    year: string,
    major: string,
    minor: string,
    position: string,
    hometown: string,
    officerTitle: string,
    imgSrc: string,
}

export default function OfficerPreview ({
    firstName,
    lastName,
    year,
    major,
    minor,
    position,
    hometown,
    officerTitle,
    imgSrc
}: OfficerPreviewProps) {
    return (
        <div id="officer-container" className="h-1/3 max-h-[250px] w-1/3 min-w-[400px] flex my-2 mx-2">
            <img src={imgSrc} className="w-1/2" alt=""></img>
            <div id="info-container" className="bg-lsu-yellow w-1/2 bg-opacity-50 overflow-hidden">
                <p className="w-full text-center text-[3vh] font-bold">{officerTitle}</p>
                <p className="mt-2 text-center text-[2vh] font-bold">{firstName} {lastName}</p>
                <p className="text-[2vh] my-2 mx-3">
                    <b>Year:</b> {year}<br/>
                    <b>Position:</b> {position} <br/>
                    <b>Major:</b> {major} <br/>
                    {minor && minor.length > 0 && <><b>Minor:</b> {minor}<br/></>}
                    <b>Hometown: </b> {hometown}
                </p>
            </div>
        </div>
    );
}