import React from 'react';

interface ImageProps {
    src: string;
    alt: string;
}

const Image: React.FC<ImageProps> = ({ src, alt }) => (
    <img src={src} alt={alt} style={{ width: '34% '}} />
);

export default Image;