interface PlayerPreviewProps {
    firstName: string,
    lastName: string,
    year: string,
    major: string,
    minor: string,
    position: string,
    hometown: string,
    imgSrc: string,
}

export default function PlayerPreview({
    firstName,
    lastName,
    year,
    major,
    minor,
    position,
    hometown,
    imgSrc
}: PlayerPreviewProps){
    let picture = "./roster-images/blank-profile-picture.webp"
    if (imgSrc.length > 1) {
        picture = imgSrc
    }

    return(
        <div id="player-container" className="h-1/3 max-h-[240px] w-1/4 min-w-[340px] min-h-[215px] flex my-2 mx-2">
            <img src={picture} className="w-1/2" alt=""></img>
            <div id="info-container" className="bg-lsu-yellow w-1/2 bg-opacity-50 overflow-hidden">
                <p className="mt-2 text-center text-[2vh] font-bold">{firstName} {lastName}</p>
                <p className="text-[2vh] my-2 mx-3">
                    <b>Year:</b> {year}<br/>
                    <b>Position:</b> {position} <br/>
                    <b>Major:</b> {major} <br/>
                    {minor && minor.length > 0 && <><b>Minor:</b> {minor}<br /></>}
                    <b>Hometown: </b> {hometown}
                </p>
            </div>
        </div>
    ); 
}