import PlayerPreview from "../../components/PlayerPreview";
import OfficerPreview from "../../components/OfficerPreview";
import { OptionFields } from '../../lib/OptionFields';
import { useState } from "react";

interface RosterInfo {
    firstName: string,
    lastName: string,
    year: string,
    major: string,
    minor: string,
    position: string,
    hometown: string,
    officer: boolean,
    officerTitle?: string,
    imgSrc: string,
}

const Spring23RosterData: RosterInfo[] = [
    {firstName: "Anna", lastName: "Huffman", year: "Sophomore", major: "Kinesiology", minor:"",position: "Winger", hometown: "Mandeville, LA", officer: false, imgSrc: "./roster-images/Anna.jpg"},
    {firstName: "Ashley", lastName: "Burke", year: "Senior", major: "Biochemistry", minor:"", position: "Winger", hometown: "Mandeville, LA", officer: false, imgSrc: "./roster-images/Ashley.jpg"},
    {firstName: "Bailey", lastName: "D'Aunoy", year: "Junior", major: "Kinesiology", minor:"", position: "Defender", hometown: "Kenner, LA", officer: false, imgSrc: "./roster-images/Bailey.jpg"},
    {firstName: "Beverly", lastName: "Richard", year: "Senior", major: "Computer Science", minor: "Business", position: "Defender", hometown: "Lafayette, LA", officer: true, officerTitle: "Treasurer", imgSrc: "./roster-images/Beverly.jpg"},
    {firstName: "Camille", lastName: "Blanchard", year: "Sophomore", major: "Psychology", minor:"", position: "Defender", hometown: "Lafayette, LA", officer: false, imgSrc: "./roster-images/Camille-Blanchard.jpg"},
    {firstName: "Camille", lastName: "Carriere", year: "Senior", major: "Mechanical Engineering", minor:"", position: "Defender", hometown: "Austin, TX", officer: true, officerTitle: "Secretary", imgSrc: "./roster-images/Camille-Carriere.jpg"},
    {firstName: "Camille", lastName: "Messina", year: "Senior", major: "ISDS", minor:"Data Analytics", position: "Midfielder", hometown: "Fayetteville, GA", officer: true, officerTitle: "Vice President", imgSrc: "./roster-images/Camille-Messina.jpg"},
    {firstName: "Colette", lastName: "Smith", year: "Freshman", major: "Accounting", minor:"", position: "Winger", hometown: "Baton Rouge, LA", officer: false, imgSrc: "./roster-images/Colette.jpg"},
    {firstName: "Delaney", lastName: "Zybko", year: "Junior", major: "Kinesiology", minor: "Biology", position: "Forward", hometown: "Praireville, LA", officer: true, officerTitle: "Tactical Officer", imgSrc: "./roster-images/Delaney.jpg"},
    {firstName: "Grace", lastName: "Greenwood", year: "Sophomore", major: "Kinesiology", minor:"", position: "Winger / Defender", hometown: "Readington, NJ", officer: true, officerTitle: "Social Media Chair", imgSrc: "./roster-images/Grace.jpg"},
    {firstName: "Isabelle", lastName: "Losavio", year: "Sophomore", major: "Architecture", minor:"", position: "Midfielder", hometown: "Apex, NC", officer: false, imgSrc: "./roster-images/Isabelle.jpg"},
    {firstName: "Lily", lastName: "Mittendorf", year: "Freshman", major: "Political Science", minor:"", position: "Midfielder", hometown: "Baton Rouge, LA", officer: false, imgSrc: "./roster-images/Lily.jpg"},
    {firstName: "Madison", lastName: "Trauth", year: "Freshman", major: "Kinesiology Pre-PT", minor:"", position: "Midfielder / Forward", hometown: "New Orleans, LA", officer: false, imgSrc: "./roster-images/Madison.jpg"},
    {firstName: "Mariah", lastName: "Garcia", year: "Sophomore", major: "Kinesiology Pre-AT", minor: "Psychology", position: "Midfielder", hometown: "Los Angeles, CA", officer: false, imgSrc: "./roster-images/Mariah.jpg"},
    {firstName: "Mikela", lastName: "King-Cesare", year: "Sophomore", major: "Sociology Pre-Law", minor:"", position: "Goalkeeper", hometown: "New York City, NY", officer: false, imgSrc: "./roster-images/Mikela.jpg"},
    {firstName: "Olivia", lastName: "Herrington", year: "Junior", major: "Psychology", minor: "Leadership Development", position: "Defender", hometown: "Baton Rouge, LA", officer: false, imgSrc: "./roster-images/Olivia.jpg"},
    {firstName: "Samantha", lastName:"Falgoust", year: "Senior", major:"Kinesiology Pre-OT", minor: "", position: "Forward", hometown:"Metarie, LA", officer: true, officerTitle: "President", imgSrc: "./roster-images/Samantha.jpg"}
];

const Fall23RosterData: RosterInfo[] = [
    {firstName: "Anna", lastName: "Huffman", year: "Junior", major: "Kinesiology", minor:"",position: "Winger", hometown: "Mandeville, LA", officer: true, officerTitle: "Social Media Chair", imgSrc: "./roster-images/Anna.jpg"},
    {firstName: "Beverly", lastName: "Richard", year: "Senior", major: "Computer Science", minor: "Business", position: "Defender", hometown: "Lafayette, LA", officer: true, officerTitle: "President", imgSrc: "./roster-images/Beverly.jpg"},
    {firstName: "Camille", lastName: "Blanchard", year: "Junior", major: "Psychology", minor:"", position: "Defender", hometown: "Lafayette, LA", officer: true, officerTitle: "Tactical Officer", imgSrc: "./roster-images/Camille-Blanchard.jpg"},
    {firstName: "Camille", lastName: "Carriere", year: "Senior", major: "Mechanical Engineering", minor:"", position: "Defender", hometown: "Austin, TX", officer: true, officerTitle: "Tactical Officer", imgSrc: "./roster-images/Camille-Carriere.jpg"},
    {firstName: "Isabelle", lastName: "Losavio", year: "Junior", major: "Architecture", minor:"", position: "Midfielder", hometown: "Apex, NC", officer: true, officerTitle: "Secretary", imgSrc: "./roster-images/Isabelle.JPG"},
    {firstName: "Kiki", lastName: "Bacil", year: "Junior", major: "Economics", minor:"", position: "Defender", hometown: "CA", officer: true, officerTitle: "Treasurer", imgSrc: "./roster-images/Keke.jpg"},
    {firstName: "Lily", lastName: "Mittendorf", year: "Sophomore", major: "Political Science", minor:"", position: "Midfielder", hometown: "Baton Rouge, LA", officer: true, officerTitle: "Fundraising Chair", imgSrc: "./roster-images/Lily.jpg"},
    {firstName: "Mariah", lastName: "Garcia", year: "Junior", major: "Kinesiology Pre-AT", minor: "Psychology", position: "Midfielder", hometown: "Los Angeles, CA", officer: true, officerTitle: "Vice President", imgSrc: "./roster-images/Mariah.jpg"},
    {firstName: "Mikela", lastName: "King-Cesare", year: "Junior", major: "Sociology Pre-Law", minor:"", position: "Goalkeeper", hometown: "New York City, NY", officer: true, officerTitle: "GK Tactical Officer", imgSrc: "./roster-images/Mikela.jpg"},
    {firstName: "Bailey", lastName: "D'Aunoy", year: "Senior", major: "Kinesiology", minor:"", position: "Defender", hometown: "Kenner, LA", officer: false, imgSrc: "./roster-images/Bailey.jpg"},
    {firstName: "Colette", lastName: "Smith", year: "Sophomore", major: "Accounting", minor:"", position: "Winger", hometown: "Baton Rouge, LA", officer: false, imgSrc: "./roster-images/Colette.jpg"},
    {firstName: "Elie", lastName: "Robbins", year: "Senior", major: "Fashion Design", minor: "", position: "Midfielder", hometown: "FL", officer: false, imgSrc: ""},
    {firstName: "Georgia", lastName: "Szoke", year: "Sophomore", major: "Economics", minor: "", position: "Forward", hometown: "Baltimore, MD", officer: false, imgSrc: ""},
    {firstName: "Madison", lastName: "Trauth", year: "Sophomore", major: "Kinesiology Pre-PT", minor:"", position: "Midfielder / Forward", hometown: "New Orleans, LA", officer: false, imgSrc: "./roster-images/Madison.jpg"},
    {firstName: "Olivia", lastName: "Herrington", year: "Senior", major: "Psychology", minor: "Leadership Development", position: "Defender", hometown: "Baton Rouge, LA", officer: false, imgSrc: "./roster-images/Olivia.jpg"},
    {firstName: "Zoey", lastName: "Steele", year: "Freshman", major: "Business", minor: "", position: "Forward", hometown: "Bend, OR", officer: false, imgSrc: ""}
];

interface RosterMap {
    [key: string]: RosterInfo[];
}

const formOptions: OptionFields[] = [
    {name: 'Fall 2023 Roster', value: 'fall23Roster'},
    {name: 'Spring 2023 Roster', value: 'spring23Roster'},
];

const roster: RosterMap = {
    'fall23Roster': Fall23RosterData,
    'spring23Roster': Spring23RosterData
}

// Want to display officers in a specific order; starting with president, then vp, etc.
const setOfficerIndex = (officers: RosterInfo[]) => {
    const officerTitles = [
        "President", "Vice President", "Tactical Officer", "Treasurer", "Secretary", "GK Tactical Officer", "Social Media Chair", "Fundraising Chair"
    ];
    const officersInOrder: RosterInfo[] = []
    officerTitles.forEach((position) => {
        if(position === "Tactical Officer") {
            const tacticalOfficers: RosterInfo[] = officers.filter(officer => officer.officerTitle === "Tactical Officer")
            tacticalOfficers.forEach(tacticalOfficer => officersInOrder.push(tacticalOfficer))
        }
       else {
            const officer = officers.find(officer => officer.officerTitle === position)
            if ( officer != null ) {
                officersInOrder.push(officer)
            }
        }
    })
    return officersInOrder
}

const getPlayerCards = (players: RosterInfo[]) => {
    const playerCards = players.map((player, index) =>
        <PlayerPreview 
            key={index}
            firstName = {player.firstName}
            lastName = {player.lastName}
            year = {player.year}
            major = {player.major}
            minor = {player.minor}
            position= {player.position}
            hometown= {player.hometown}
            imgSrc= {player.imgSrc}
        />
    );
    return playerCards;
}

const getOfficerCards = (officers: RosterInfo[]) => {
    const officerCards = officers.map((officer, index) => 
        <OfficerPreview
            key={index}
            firstName={officer.firstName}
            lastName={officer.lastName}
            year={officer.year}
            major={officer.major}
            minor={officer.minor}
            position={officer.position}
            hometown={officer.hometown}
            officerTitle={officer.officerTitle!}
            imgSrc={officer.imgSrc}
        />
    );

    return officerCards;
}

interface RosterFormProps {
    selectedOption?: string;
    formOptions: OptionFields[];
}

export default function Roster() {
    const[formData, setFormData] = useState<RosterFormProps>({
        selectedOption: "fall23Roster",
        formOptions,
    });

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.value;
        setFormData({ ...formData, selectedOption });
    }

    const getRoster = () => {
        if(formData.selectedOption !== undefined) {
            if(Array.isArray(roster[formData.selectedOption])) {
                const selectedRoster = roster[formData.selectedOption];
                const officers = setOfficerIndex(selectedRoster.filter((player) => player.officer));
                const players = selectedRoster.filter((player) => player.officer === false);
                
                return (
                    <div className="mt-6 mx-16 h-screen">
                        <div className="text-center text-3xl text-lsu-purple my-4">
                        Executive Officers
                        </div>
                        <div className="flex flex-wrap justify-center">
                            {getOfficerCards(officers)}
                        </div>
                            <div className="text-center text-3xl text-lsu-purple my-4">
                            Members
                            </div>
                            { players.length === 0 &&
                                <div className="text-center text-xl text-lsu-purple my-4 pb-8">
                                    The Fall 2023 roster has not been selected yet. Please see our <a className="font-medium underline" href="https://www.instagram.com/lsuwcsoccer/">Instagram</a> for tryout dates!
                                </div>
                            }
                        <div className="flex flex-wrap justify-center">
                            {getPlayerCards(players)}
                        </div>
                    </div>
                );
            }
        }
    }
    return(
        <>
            <div className="flex justify-center items-center">
                <select className="ml-14 text-lsu-purple font-bold text-3xl" id="formOptions" onChange={ handleSelectChange }>
                    {formOptions.map((option, index) =>(
                        <option key={index} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                </select>
            </div>
            { getRoster() }
        </>
    )
}