import ScheduleForm from "../../components/ScheduleForm";

export default function Schedule(){
    return (
        <>
            <div>
                <title>Schedule</title>
            </div>
            <div className="h-screen">
                <div className="flex items-center justify-center font-bold mb-5">
                   <ScheduleForm />
                </div>
            </div>
        </>
    )
}