import { useState } from 'react';
import { OptionFields } from '../lib/OptionFields';
import SchedulePreview from './SchedulePreview';
import { ScheduleInfo } from '../lib/ScheduleInfo';

interface ScheduleFormProps {
    selectedOption?: string;
    formOptions: OptionFields[];
}

interface ScheduleMap {
    [key: string]: ScheduleInfo[]; // adding an index signature to allow string indexing 
}

const scheduleFall23: ScheduleInfo[] = [
    { opponent: "University of Texas", opponentAbbrev: "UT", date: new Date(2023, 8, 3), time: "4:00pm", location: "Austin, TX", results: "T 0-0"},
    { opponent: "Texas Christian University", opponentAbbrev: "TCU", date: new Date(2023, 8, 10), time: "11:00am", location: "Baton Rouge, LA", results: "W 1-0"},
    { opponent: "Rice University", opponentAbbrev: "Rice", date: new Date(2023, 8, 16), time: "1:00pm", location: "Baton Rouge, LA", results: "W 4-0"},
    { opponent: "Tulane University", opponentAbbrev: "Tulane", date: new Date(2023, 8, 17), time: "11:00am", location: "Baton Rouge, LA", results: "W 4-0"},
    { opponent: "Baylor University", opponentAbbrev: "Baylor", date: new Date(2023, 8, 30), time: "1:00pm", location: "Waco, TX", results: "L 0-1"},
    { opponent: "Texas A&M", opponentAbbrev: "TAMU", date: new Date(2023, 9, 1), time: "11:00am", location: "College Station, TX", results: "W 1-0"},
    { opponent: "University of Louisiana", opponentAbbrev: "UL", date: new Date(2023, 9, 20), time: "6:00pm", location: "Baton Rouge, LA", results: "W 15-0"},
    { opponent: "University of Mississippi", opponentAbbrev: "Ole Miss", date: new Date(2023, 9, 22), time:"10:00am", location: "Baton Rouge, LA", results: "W 1-0"},
    { opponent: "University of Maryland", opponentAbbrev: "Maryland", date: new Date(2023, 10, 16), time:"8:00am", location: "Round Rock, TX", results: "L 0-2", tournamentName: "NIRSA Nationals"},
    { opponent: "James Madison University", opponentAbbrev: "JMU", date: new Date(2023, 10, 16), time:"3:00pm", location: "Round Rock, TX", results: "W 4-1", tournamentName: "NIRSA Nationals"},
    { opponent: "Pennsylvania State University", opponentAbbrev: "Penn State", date: new Date(2023, 11, 17), time:"8:00am", location: "Round Rock, TX", results: "W 2-1", tournamentName: "NIRSA Nationals"},
    { opponent: "University of Oregon", opponentAbbrev: "Oregon", date: new Date(2023, 10, 17), time:"3:00pm", location: "Round Rock, TX", results: "L 2-0", tournamentName: "NIRSA Nationals", playoff: "Quarterfinals"}
];

const scheduleSpring23: ScheduleInfo[] = [
    { opponent: "University of Texas at San Antonio", opponentAbbrev: "UTSA", date: new Date(2023, 2, 3), time: "6:30pm", location: "San Antonio, TX", results: "W 5-0"},
    { opponent: "Tulane University", opponentAbbrev: "Tulane", date: new Date(2023, 2, 10), time: "6:00pm", location: "Baton Rouge, LA", results: "W 5-0"},
    { opponent: "Texas A&M University Corps of Cadets", opponentAbbrev: "TAMUCC", date: new Date(2023, 2, 25), time: "3:00pm", location: "College Station, TX", results: "W 12-0"}
];

const scheduleFall22: ScheduleInfo[] = [
    { opponent: "Tulane University", opponentAbbrev: "Tulane", date: new Date(2022, 8, 3), time: "1:00pm", location: "Baton Rouge, LA", results: "W 8-0"},
    { opponent: "Texas Christian University", opponentAbbrev: "TCU", date: new Date(2022, 8, 11), time: "11:00am", location: "Fort Worth, TX", results: "W 3-1"},
    { opponent: "University of Texas", opponentAbbrev: "UT", date: new Date(2022, 9, 1), time: "11:00am", location: "Houston, TX", results: "T 2-2"},
    { opponent: "University of North Texas", opponentAbbrev: "UNT", date: new Date(2022, 9, 2), time: "11:00am", location: "Denton, TX", results: "W 6-0"},
    { opponent: "Baylor University", opponentAbbrev: "Baylor", date: new Date(2022, 9, 15), time: "10:00am", location: "Baton Rouge, LA", results: "L 2-3"},
    { opponent: "Texas A&M University", opponentAbbrev: "TAMU", date: new Date(2022, 9, 16), time: "11:00am", location: "Baton Rouge, LA", results: "L 0-3"}
];

const scheduleSpring22: ScheduleInfo[] = [
    { opponent: "Rice University", opponentAbbrev: "Rice", date: new Date(2022, 1, 5), time: "1:00pm", location: "Baton Rouge, LA", results: "W 2-1"},
    { opponent: "Tulane Univeristy", opponentAbbrev: "Tulane", date: new Date(2022,1, 10), time: "1:00pm", location: "New Orleans, LA", results: "L 0-1"},
    { opponent: "Tulane University", opponentAbbrev: "Tulane", date: new Date(2022, 2, 5), time: "1:00pm", location: "Baton Rouge, LA", results: "W 4-0"},
];

const scheduleFall21: ScheduleInfo[] = [
    { opponent: "University of Houston", opponentAbbrev: "UH", date: new Date(2021, 8, 4), time: "1:00pm", location: "Baton Rouge, LA", results: "W 2-1"},
    { opponent: "Rice University", opponentAbbrev: "Rice", date: new Date(2021, 8, 25), time: "1:00pm", location: "Houston, TX", results: "W 1-0"},
    { opponent: "University of Houston Downtown", opponentAbbrev: "UHD", date: new Date(2021, 9, 3), time: "11:00am", location: "Baton Rouge, LA", results: "W 9-0"},
    { opponent: "Stephen F. Austin", opponentAbbrev: "SFA", date: new Date(2021, 9, 9), time: "3:00pm", location: "Baton Rouge, LA", results: "W 12-0"},
    { opponent: "Texas A&M University at Galveston", opponentAbbrev: "TAMUG", date: new Date(2021, 9, 23), time: "1:00pm", location: "Baton Rouge, LA", results: "W 5-0"},
    { opponent: "Texas A&M University", opponentAbbrev: "TAMU", date: new Date(2021, 9, 17), time: "1:00pm", location: "College Station, TX", results: "L 0-2"}
];

const formOptions: OptionFields[] = [
    {name: 'Fall 2023', value: "scheduleFall23"},
    {name: 'Spring 2023', value: "scheduleSpring23"},
    {name: 'Fall 2022', value: "scheduleFall22"}, 
    {name: 'Spring 2022', value: "scheduleSpring22"},
    {name: 'Fall 2021', value: "scheduleFall21"},
];

const schedule: ScheduleMap = {
    "scheduleFall23": scheduleFall23,
    "scheduleFall21": scheduleFall21,
    "scheduleSpring22": scheduleSpring22,
    "scheduleFall22": scheduleFall22,
    "scheduleSpring23": scheduleSpring23,
};

const ScheduleForm = () => {
    const[formData, setFormData] = useState<ScheduleFormProps>({
        selectedOption: "",
        formOptions,
    });

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.value;
        setFormData({ ...formData, selectedOption});
    };

    /*
    * This method returns the schedule preview of a schedule array
    * If a schedule has not been selected yet, it will return a prompt to select a schedule
    * It then checks to see if the selected schedule is in the schedule array
    * If it can find that requested schedule, it will render a SchedulePreview component array
    * The Schedule Preview takes in all the relevant information about a game and formats it and displays it
    */
    const GetGames = () => {
        if(formData.selectedOption !== undefined){ // make sure an option has been selected first
            if(Array.isArray(schedule[formData.selectedOption])) {
                const selectedSchedule = schedule[formData.selectedOption];
                const games = selectedSchedule.map((game, index) =>
                    <SchedulePreview
                        key={index}
                        opponent= {game.opponent}
                        opponentAbbrev={game.opponentAbbrev}
                        date = {game.date}
                        time = {game.time}
                        location={game.location}
                        results={game.results}
                        tournamentName={game.tournamentName ?? ''}
                        playoff={game.playoff ?? ''}
                    />
                );
                return games;
            }
        }
    }

    return (
        <div className="w-full">
            <div className="relative mb-12">
                <img src="./juggle.JPG" alt="" className="w-screen object-cover"></img>
                <div className="absolute bg-lsu-purple inset-0 bg-opacity-50 flex justify-center items-end">
                    <label className="text-lsu-yellow font-bold text-2xl pb-2" htmlFor="formOptions">Schedule for:</label>
                    <select className="text-lsu-yellow font-bold text-xl ml-3 pb-2" id="formOptions" onChange={ handleSelectChange }>
                        <option value="">Select</option>
                        {formOptions.map((option, index) =>(
                            <option key={index} value={option.value}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            { GetGames() }
        </div>
    );
}

export default ScheduleForm;