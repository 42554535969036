// TODO:
// finish styling form
// connect to database to verify login

import { SetStateAction, useState } from 'react';
// import ReactDOM from 'react-dom/client';
// import mysql from 'mysql';
// import inquirer from 'inquirer';

export default function OfficerLogin() {

    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');

    const handleChange = (e: { target: { value: SetStateAction<string>; }; }) => {
        setEmail(e.target.value);
    }
    
    const handlePwdChange = (e: { target: { value: SetStateAction<string>; }; }) => {
        setPwd(e.target.value);
    }

    const handleSubmit = (e: any) => {
        // check with DB
        alert("success");
        e.preventDefault();
    }

    return (
        <form onSubmit={(e) => {handleSubmit(e)}}
            className="mt-10 h-3/4 w-3/5 bg-light-purple absolute text-center py-12 -translate-x-1/2 -translate-y-1/2 top-2/4 left-2/4 rounded-3xl">
            <h1 className="text-white text-3xl font-medium leading-10 tracking-wide">Officer Login</h1>
            <label className="block mt-7 text-xl text-white tracking-wide">LSU Email:
                <br></br>
                <input className="block w-2/3 bg-[#DED8E6] rounded-lg mx-auto h-10 px-3 mt-2"
                    type="text"
                    name="email"
                    placeholder="LSU email"
                    value={email} required
                    onChange={(e) => {handleChange(e)}}
                />
            </label>
            <br></br>
            <label className="block mt-7 text-xl text-white tracking-wide">Password:
                <br></br>
                <input className="block w-2/3 bg-[#DED8E6] rounded-lg mx-auto h-10 px-3 mt-2"
                    type="text"
                    name="email"
                    placeholder="Password"
                    value={pwd} required
                    onChange={(e) => {handlePwdChange(e)}}
                />
            </label><br></br>
            <div>
                <input type="submit" value="Log in" className="tracking-wide font-medium text-xl rounded-lg h-10 w-2/3 mx-auto mt-12 bg-white cursor-pointer"/>
            </div>
        </form>
    )
}