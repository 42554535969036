import { GoLocation } from 'react-icons/go';

interface SchedulePreviewProps {
    opponent?: string,
    opponentAbbrev?: string,
    date?: Date,
    time?: string,
    location?: string,
    results?: string,
    tournamentName?: string,
    playoff?: string
}

export default function SchedulePreview({
    opponent,
    opponentAbbrev,
    date,
    time,
    location,
    results,
    tournamentName,
    playoff
}: SchedulePreviewProps) {
    let month = date?.getMonth()!;
    let day = date?.getDate();
    let year = date?.getFullYear();

    return (
        <div id="game-container" className="bg-lsu-purple m-auto mb-5 h-[10vh] w-3/5 rounded-lg">
            <div id="date-container" className="bg-white rounded-lg float-left w-2/12 pt-[2vh] h-full border-lsu-purple border-4 text-center">
                <div className="font-bold text-[1vw] pb-0 mb-0">{ month + 1 }/{ day }/{ year }{"\n"}{ time }</div>
            </div>
            <div id="results-container" className="bg-white rounded-lg float-right w-2/12 h-full border-lsu-purple border-4 text-center">
                <div className="font-bold text-[1vw] pb-0 mb-0">Results: { results }</div>
            </div>
            <div id="info-container" className="h-full text-white font-bold text-[2vw] pt-1">
                { tournamentName !== '' && 
                    <div id="tournament-container" className='text-lsu-yellow text-[1vw] flex-row'>
                        {tournamentName}{playoff !== '' && ` - ${playoff}`}
                    </div>}
                <div className="flex flex-row">
                    LSU vs. { opponentAbbrev }
                    <div className="ml-2 text-[1vw] mt-4">{"("}{opponent}{")"}</div>
                </div>
                <div id="location-container" className="text-white text-[1vw] flex-row">
                    <GoLocation className="inline-block pb-1"/>
                    { location }
                </div>
            </div>
        </div>
    )
}