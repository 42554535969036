import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
/*
import express from 'express';
import compression from 'compression';
import helmet from 'helmet';
import cors from 'cors';
import * as path from 'path';
import * as MySQLConnector from './api/utils/mysql.connector';
import About from './routes/about/About';
import Home from './routes/home/Home';
import Media from './routes/media/Media';
import OfficerLogin from './routes/officerlogin/OfficerLogin';
import Roster from './routes/roster/Roster';
import Schedule from './routes/schedule/Schedule';

const app = express();
const port = 3000;
const routes = [
    About, Home, Media, OfficerLogin, Roster, Schedule
];

// Create database pool
MySQLConnector.init();

// serve static files
app.use(express.static(path.join(__dirname, '../public')));

// compress all the responses
app.use(compression());

// adding set of security middlewares
app.use(helmet());

// parse incoming request body and append data to `req.body`
app.use(express.json());
app.use(express.urlencoded({ extended: true }));

// enable all CORS request
app.use(cors());

app.use('/routes/', routes);

app.listen(port, () => {
    console.log(`Example app listening at http://www.lsuwcsoccer:${port}`)
});
*/
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
