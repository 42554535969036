import ImagesContainer from '../../components/ImagesContainer';
import AboutContainer from '../../components/AboutContainer';
import DisclaimerContainer from '../../components/DisclaimerContainer';

export default function Home() {
    return (
        <div>
            <div className="relative">
                <img className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 mt-5" id="title" src="./title.png" alt=""></img>
                <img src="./homepage_image.jpg" alt=""></img>
            </div>
            <div className="w-full"> 
                <AboutContainer />
            </div> 
            <ImagesContainer />
            <DisclaimerContainer />
        </div>
    )
}