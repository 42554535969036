import React from 'react';

const DisclaimerContainer: React.FC = () => (
    <div className="flex flex-col justify-center items-center">
        <h1 className="underline mt-5 text-xl font-bold">
            Disclaimer
        </h1>
        <p className="pt-3 px-16 font-medium">
            "The statements and opinions included in the LSU Women's Club Soccer pages are those of the LSU Women's Club Soccer only. Any statements and opinions included in these pages are not those of LSU or the LSU Board of Supervisors."
        </p>
        <p className="pt-3 font-medium">© 2023</p>
    </div>
);

export default DisclaimerContainer;