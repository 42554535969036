import React from 'react';

const AboutContainer: React.FC = () => (
    <div className="flex flex-row justify-center items-center">
        <div className="my-10 w-3/4 h-4/5 shadow-md text-white text-center bg-lsu-purple rounded-lg">
            <h1 className="font-bold mt-2 text-2xl underline">Who We Are</h1>
                <p className="px-10 pt-3 font-medium">The LSU Women's Club Soccer team is a sport club that has been formed by LSU students 
                    whose goals are to continue playing the sport we all know and love. The club is also 
                    meant to be a learning experience for those involved through their involvement in organization administration, 
                    teamwork, scheduling, budgeting, fundraising, and public relations. Participants are encouraged to develop their
                    skills, engage in competition, make meaningful connections, and most importantly have fun.
                </p>
                <h2 className="font-bold text-2xl pt-7 underline">What our season looks like</h2>
                <p className="px-10 pt-3 font-medium">
                    We compete against other sport clubs at different universities. During the fall and spring semesters we compete in the Texas Collegiate Soccer League
                    The Regional Tournament is for teams that qualify and is usually in late October or November. Our season typically consists of
                    about 7-10 games a semester that are located in either Baton Rouge, New Orleans, or Texas. We play our games on the weekends and we 
                    have practice twice a week.
                </p>
                <h3 className="font-bold text-2xl pt-7 underline">How to join</h3>
                <p className="px-10 pt-3 font-medium mb-3">
                    Participants wanting to join must be a full time LSU student and be of good academic standing. Tryouts are usually the first 
                    or second week of school for both fall and spring semesters. Specific tryout information is always posted on our <a className="font-medium underline" href="https://www.instagram.com/lsuwcsoccer/">Instagram</a>.
                </p>
        </div>
    </div>
);

export default AboutContainer;