import Navbar from './components/Navbar';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

import Home from './routes/home/Home';
import Schedule from './routes/schedule/Schedule';
import Roster from './routes/roster/Roster';
import Media from './routes/media/Media';
import Officers from './routes/officers/Officers';
import { Pages } from './lib/Pages';


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route 
        index
        element={
          <>
            <Navbar active={Pages.Home} />
            <Home />
          </>
        }
      />
     
      <Route path="schedule">
        <Route
          index
          element={
            <>
              <Navbar active={Pages.Schedule} />
              <Schedule />
            </>
          }
        />
      </Route>
      <Route path="roster">
        <Route
          index
          element={
            <>
              <Navbar active={Pages.Roster} />
              <Roster />
            </>
          }
        />
      </Route>
      <Route path="media">
        <Route
          index
          element={
            <>
              <Navbar active={Pages.Media} />
              <Media />
            </>
          }
        />
      </Route>
      <Route path="officers">
          <Route 
            index
            element={
              <>
                <Navbar active={Pages.Officers} />
                <Officers />
              </>
            }
          />
      </Route>
    </Route>
  )
)

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;