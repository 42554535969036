import React from "react";
// { Link, useNavigate } from "react-router-dom";
import { Pages } from '../lib/Pages';
import { Link } from "react-router-dom";

const LOGO = "./logo.jpg";

function classNames(...classes: string[]){
    return classes.filter(Boolean).join(" ");
}

export default function Navbar({ active }: { active: Pages }){
    //const navigate = useNavigate();

    const navigation = [
        { name: "Home", to: "/", current: active === Pages.Home },
        { name: "Schedule", to: "/schedule", current: active === Pages.Schedule },
        { name: "Roster", to: "/roster", current: active === Pages.Roster },
        //{ name: "Media", to: "/media", current: active === Pages.Media }, 
        //{ name: "Officers", to: "/officers", current: active === Pages.Officers },
    ];

    return (
        <div className="justify-between">
            <div className="flex pt-5">
                <img src={ LOGO } alt="./logo.jpg" className="max-h-16 px-5"></img>
                <div className="pt-4">
                    {navigation.map((item) => (
                        <Link 
                            key={item.name}
                            to={item.to}
                            className={classNames(
                                item.current
                                    ? "bg-lsu-purple text-white"
                                    : "text-gray-500 hover:bg-lsu-purple hover:text-white",
                                "px-5 py-2 rounded-md text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                        >
                            {item.name}
                        </Link>
                    ))}
                </div>
            </div>
        </div>
       
    )
};